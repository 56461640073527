<template>
    <div v-if="shops">
        <div v-if="selfOrderEnabled">
            <div v-if="isPermission">
                <div class="p-0 tabs">
                    <input type="radio" id="tab1" name="tab-control" checked />
                    <input type="radio" id="tab2" name="tab-control" />
                    <ul class="ulbar font-weight-normal text-dark">
                        <li :title="$t('smartSettingTitle')">
                            <label for="tab1" role="button">
                                <em class="fa fa-user d-lg-none d-md-none"></em>
                                <br /><span> {{ $t('smartSettingTitle') }}</span>
                            </label>
                        </li>
                        <li :title="$t('settingKitchenOpenTimes')">
                            <label for="tab2" role="button">
                                <em class="fa fa-money d-lg-none d-md-none"></em>
                                <br /><span> {{ $t('settingSelfService') }}</span>
                            </label>
                        </li>
                    </ul>
                    <div id="selfServiceSlider" class="slider">
                        <div class="indicator"></div>
                    </div>
                    <div class="content">

                        <section id="selfServiceParticipatingProducts" class="text-dark">
                            <template>
                                <span hidden>{{ selfOrderEnabled }}</span>
                                <div id="selfServiceTable" class="table">
                                    <div class="row">
                                        <div class="col-10">
                                            <h3 id="selfServiceProductsHeader" class="font-weight-normal text-dark">{{ $t('ParticipatingProducts') }}
                                            </h3>
                                            <h6 id="selfServiceDescription" class="text-description text-dark">{{ $t('descriptionSilomOrder') }}
                                            </h6>
                                        </div>
                                        <div class="col-2">
                                            <div class="row">
                                                <div id="selfServiceStatusPopup" class="mr-1" v-if="isEditData" @click="statusPopup">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CRow>
                                    <CCol sm="5" md="5" lg="5" class="mt-2">
                                        <select id="selfServiceCategorySelect" class="custom-select" v-on:change="getProductByCategory($event)">
                                            <option value="">{{ $t('allProduct') }}</option>
                                            <option id="selfServiceCategoryOption" :value="item.objectId" v-for="(item, index) in category"
                                                :key="index">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </CCol>
                                    <CCol sm="5" md="5" lg="5" class="mt-2">
                                        <input id="selfServiceSearchInput" v-model.trim="keyword" class="cursor form-control"
                                            :placeholder="$t('searchProduct')" @input="getProduct()" />
                                    </CCol>
                                    <CCol sm="2" md="2" class="mt-2">
                                        <CButton id="selfServiceSearchButton" v-on:click="getProduct()" block color="success">{{ $t('search') }}
                                        </CButton>
                                    </CCol>
                                </CRow>
                                <CRow class="mt-3">
                                    <CCol sm="12" lg="12">
                                        <div v-if="loadingButton == true">
                                            <CDataTable id="selfServiceDataTable" :fields="fields" :items="items" :loading="loading" striped hover
                                                clickableRows style="white-space: nowrap;" @row-clicked="toggleDetails">
                                                <template #remoteImagePath="{ item, index }">
                                                    <td :id="'selfServiceDataTableItem' + index">
                                                        <div v-if="item.remoteImagePath" class="square-box" :style="{
                                                            'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                                                            'background-size': 'cover',
                                                            'background-position': 'center center',
                                                            'width': 'auto',
                                                            'height': '40px',
                                                            'border-radius': '3px',
                                                            'aspect-ratio': '1.3'
                                                        }"></div>
                                                        <div v-else class="square-box" :style="{
                                                            backgroundColor: item.backgroundColor,
                                                            width: 'auto',
                                                            'background-origin': 'content-box',
                                                            'border-radius': '3px',
                                                            'height': '40px',
                                                            'aspect-ratio': '1.3'
                                                        }"></div>
                                                    </td>
                                                </template>
                                                <template #onhandQty="{ item }">
                                                    <td class="text-right">
                                                        <h6 v-if="floatValue(item.onhandQty) < 0" class="text-danger">
                                                            {{ item.onhandQty }}
                                                        </h6>
                                                        <h6 v-else>
                                                            {{ item.onhandQty }}
                                                        </h6>
                                                    </td>
                                                </template>
                                                <template #status="{ item }">
                                                    <td class="text-right">
                                                        <CSwitch v-if="isEditData && selfOrderEnabled" color="success"
                                                            :checked.sync="item.isMenu"
                                                            @update:checked="updateData($event, item, 'isMenu')">
                                                        </CSwitch>
                                                        <CSwitch v-else color="success" :checked.sync="item.isMenu"
                                                            disabled>
                                                        </CSwitch>
                                                    </td>
                                                </template>
                                                <template #recommend="{ item }">
                                                    <td class="text-right">
                                                        <CSwitch v-if="isEditData && selfOrderEnabled" color="warning"
                                                            :checked.sync="item.isRecommend"
                                                            @update:checked="updateData($event, item, 'isRecommend')">
                                                        </CSwitch>
                                                        <CSwitch v-else color="warning" :checked.sync="item.isRecommend"
                                                            disabled>
                                                        </CSwitch>
                                                    </td>
                                                </template>
                                                <template #details="{ item, index }" @click="toggleDetails(item)" >
                                                    <CCollapse :show="details.includes(item)" :id="'selfServiceDetailsCollapse'+ index">
                                                        <CCardBody border-color="light">
                                                            <h5 class="font-weight-normal text-dark">{{ $t('productPLU')
                                                                }}</h5>
                                                            <table :id="'selfServiceDetailsTable' + index" style="width:100%" class="table table-borderless">
                                                                <th style="width:20%">{{ $t('pluCode') }}</th>
                                                                <th>{{ $t('productName') }}</th>
                                                                <th style="width:10%" class="text-right">{{
                                                                    $t('SKURatio') }}
                                                                </th>
                                                                <tr v-for="ProductPLU in item.ProductPLU"
                                                                    :key="ProductPLU.id">
                                                                    <td>
                                                                        <h6 class="text-dark">{{ ProductPLU.PLUCode }}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 class="text-dark">{{ ProductPLU.name }}</h6>
                                                                    </td>
                                                                    <td class="text-right">
                                                                        <h6 class="text-dark">{{ ProductPLU.SKURatio }}
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </template>
                                                <template #no-items-view>
                                                    <div class="text-center my-5">
                                                        <h4 style="color: #ced2d8;">{{ $t('noItem') }}</h4>
                                                    </div>
                                                </template>
                                            </CDataTable>
                                        </div>
                                        <CListGroupItem v-if="loadingButton == false">
                                            <div class="text-center" width="100%">
                                                <button class="btn" type="button" disabled style="color: darkcyan">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true">
                                                    </span>
                                                    กำลังโหลด . . .
                                                </button>
                                            </div>
                                        </CListGroupItem>
                                    </CCol>
                                </CRow>
                                <pagination :meta_data="meta_data" v-on:next="getProduct"></pagination>
                            </template>

                            <CModal id="selfServiceConfirmModal" :show.sync="confirmModal" color="success" centered headers="false"
                                :title="$t('confirmUpdateInformation')" :footer="footer">
                                <template #header-wrapper class="header-modal">
                                    <header class="modal-header header-modal">
                                        <h5 class="modal-title font-weight-normal">{{ $t('confirmUpdateInformation') }}
                                        </h5>
                                        <button id="selfServiceCancelButton" type="button" aria-label="Close" class="close" @click="cancel"></button>
                                    </header>
                                </template>
                                <CRow class="justify-content-center col-md-12">
                                    <CRow class="justify-content-center col-md-12" v-if="loading === false">
                                        <h4 id="selfServiceMessage" class="text-center mt-3 mb-3" v-if="loading === false">{{ message }}</h4>
                                        <br />
                                    </CRow>
                                    <div style="text-align: center" v-else-if="loading === true">
                                        <CSpinner color="success" size="lg" class="text-success" centered />
                                    </div>
                                </CRow>
                                <template #footer>
                                    <CRow class="justify-content-center col-md-12 col-12">
                                        <CCol col="5" lg="4">
                                            <CButton id="selfServiceSaveButton" color="success" v-if="loading === false" block
                                                v-on:click="savedata">
                                                {{ $t('save') }}
                                            </CButton>
                                            <CButton  color="success" block v-else-if="loading === true" disabled>
                                                <CSpinner color="white" size="sm" /> {{ $t('save') }}
                                            </CButton>
                                        </CCol>
                                        <CCol lg="2" col="1"> </CCol>
                                        <CCol col="5" lg="4">
                                            <CButton id="selfServiceCancelButtonFooter" color="light" block @click="cancel">{{ $t('cancel') }}</CButton>
                                        </CCol>
                                    </CRow>
                                </template>
                            </CModal>
                        </section>

                        <section id="selfServiceSettingSection">
                            <template>
                                <div>
                                    <div id="selfServiceAlertStatus" v-if="alertStatus" @click="() => { alertStatus = false }">
                                        <CAlert color="success">{{ $t('saveSuccess') }}</CAlert>
                                    </div>
                                    <div id="selfServiceAlertText" v-if="alertText !== ''">
                                        <!-- <CAlert color="danger">{{ $t(alertText) }}</CAlert> -->
                                    </div>
                                    <h3 class="font-weight-normal text-dark">
                                        {{ $t('settingSelfService') }}
                                    </h3>
                                </div>
                                <!-- <div class="mt-4 text-dark">
                                    <h5 class="font-weight-normal text-dark">
                                        {{ $t('setDeliveryDomain') }}
                                    </h5>
                                    <span class="text-description text-dark">
                                        {{ $t('setDeliveryDomainDespt') }}</span>
                                    <div class="mt-3">
                                        <div class=" form-group ">
                                            <div class="input-group mb-2 mr-sm-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text ">
                                                        {{ URL }}/delivery/
                                                    </div>
                                                </div> -->

                                <!-- <input :readonly="hasPathUniqueName" v-model="shopURL"
                                                    style="min-width: 200px;" type="text" class="form-control"
                                                    @input="shopURL = shopURL.replace(/[^a-z]/g, '').slice(0, 20)">
                                                <CButton @click="copyURL()"
                                                    style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                                                    color="success">
                                                    {{ $t('copy') }}
                                                </CButton>
                                            </div>
                                        </div>
                                        <span v-if="shopURL == ''" class="text-description text-danger">
                                            * {{ $t('setDeliveryDomainDespt2') }}</span>
                                    </div>
                                </div> -->
                                <div id="selfServiceKitchenTimes" class="mt-3 text-dark">
                                    <h5 class="font-weight-normal text-dark">{{ $t('settingKitchenOpenTimesHeader')
                                        }}
                                    </h5>
                                    <span class="text-description text-dark">{{ $t('settingKitchenOpenTimesDespt')
                                        }}</span>
                                    <div class="d-flex mt-3">
                                        <CButton id="selfServiceApplyToAllButton" class="mb-2" color="secondary" @click="applyToAllDays()">{{
                                            $t('applyToAllDay')
                                        }}
                                        </CButton>
                                    </div>
                                    <table id="selfServiceKitchenTimesTable" style="width: 100%;border: 1px solid #ebedef;">
                                        <thead class="time-picker-header">
                                            <tr>
                                                <th class="pl-3"></th>
                                                <th>{{ $t('openTimes') }}</th>
                                                <th>{{ $t('closeTimes') }}</th>
                                                <th class="text-center">{{ $t('closeOn') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(day, key) in days" :key="key" class="row-padding">
                                                <td class="pl-3">{{ getDays(day.name) }}</td>
                                                <td>
                                                    <vue-timepicker id="selfServiceOpenTimePicker" hide-clear-button
                                                        input-class="form-control mt-1 mb-1" v-model="day.openTime"
                                                        :disabled="day.closed"></vue-timepicker>
                                                </td>
                                                <td>
                                                    <vue-timepicker hide-clear-button
                                                        id="selfServiceCloseTimePicker"
                                                        input-class="form-control mt-1 mb-1" v-model="day.closeTime"
                                                        :disabled="day.closed"></vue-timepicker>
                                                </td>
                                                <td id="selfServiceCloseCheckbox" @click="day.closed = !day.closed" class="text-center">
                                                    <input id="selfServiceCloseInput" style="accent-color: var(--success);" type="checkbox"
                                                        v-model="day.closed" class="mt-1 mb-1" />
                                                </td>
                                            </tr>
                                            <br>
                                        </tbody>
                                    </table>
                                    <CButton id="selfServiceSaveKitchenTimesButton" @click="saveKitchenTimes()" class="mt-4" block color="success">{{
                                        $t('save')
                                    }}</CButton>
                                </div>
                                <CModal id="selfServiceKitchenConfirmModal" class="text-dark" :show.sync="kitchenConfirmModal" color="success" centered
                                    headers="false" :title="$t('confirmUpdateInformation')" :footer="footer">
                                    <template #header-wrapper class="header-modal">
                                        <header class="modal-header header-modal">
                                            <h5 class="modal-title font-weight-normal">{{
                                                $t('confirmUpdateInformation')
                                            }}
                                            </h5>
                                            <button id="selfServiceConfirmModalCloseButton" type="button" aria-label="Close" class="close"
                                                @click="cancel"></button>
                                        </header>
                                    </template>
                                    <div>
                                        <!-- <div class=" form-group ">
                                            <div class="input-group mb-2 mr-sm-2">
                                                <div class="input-group-prepend ">
                                                    <div class="input-group-text text-dark">
                                                        <i class="fi fi-rr-link "></i>
                                                    </div>
                                                </div>
                                              
                                                <input class="form-control " :placeholder="URL + '/delivery/' + shopURL"
                                                    readonly>
                                            </div>
                                        </div> -->
                                    </div>
                                    <table id="selfServiceKitchenConfirmTable" style="width: 100%;border: 1px solid #ebedef;">
                                        <thead class="time-picker-header">
                                            <tr>
                                                <th class="pl-3 pt-1"><i class="fi fi-rr-calendar-clock"></i></th>
                                                <th>{{ $t('openTimes') }}</th>
                                                <th>{{ $t('closeTimes') }}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(day, key) in days" :key="key" class="row-padding">
                                                <td class="pl-3">{{ getDays(day.name) }}</td>
                                                <td class="p-2">
                                                    <span v-if="!day.closed"> {{ day.openTime }}</span>
                                                    <span v-else> - </span>
                                                </td>
                                                <td class="p-2">
                                                    <span v-if="!day.closed"> {{ day.closeTime }}</span>
                                                    <span v-else> - </span>
                                                </td>
                                            </tr>
                                            <br>
                                        </tbody>
                                    </table>
                                    <template #footer>
                                        <CRow id="selfServiceConfirmModalFooter" class="justify-content-center col-md-12 col-12">
                                            <CCol col="5" lg="4">
                                                <CButton id="selfServiceConfirmButton" :disabled="loading" color="success" block
                                                    @click="saveDataKitchen()">
                                                    <CSpinner v-if="loading" color="white" size="sm" />
                                                    {{ $t('confirm') }}
                                                </CButton>
                                            </CCol>
                                            <CCol lg="2" col="1"> </CCol>
                                            <CCol col="5" lg="4">
                                                <CButton id="selfServiceCancelButtonFooter" color="light" block @click="kitchenConfirmModal = false">{{
                                                    $t('cancel') }}
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </template>
                                </CModal>
                            </template>
                        </section>
                    </div>
                </div>
            </div>
            <div v-else>
                <access-data></access-data>
            </div>
        </div>
        <div v-else class="card p-4 shadow-sm">
            <div class="text-center mt-2" style="font-weight: bold; font-size: xx-large;">
                {{ $t("selfOrder") }}
                <span class="badge badge-success ml-2">{{ $t("freeTrial") }}</span>
            </div>
            <br />
            <div style="display: flex; justify-content: center; align-items: center">
                <img class="img-fluid mb-3" src="\img\qrAds.png" style="width: 55%" />
            </div>
            <div class="m-5 text-center"
                style="display: flex; flex-direction: column; align-items: center; font-weight: bolder;">
                <span>
                    {{ $t("descriptionFreeTrialLine1") }}
                </span>
                <span>
                    {{ $t("descriptionFreeTrialLine2") }}
                </span>
                <span style="color: #3abc98;">
                    {{ $t("descriptionFreeTrialLine3") }}
                </span>
                <span>
                    {{ $t("descriptionFreeTrialLine4") }}
                </span>
                <br />

                <CButton class="line-btn" ref="https://liff.line.me/1645278921-kWRPP32q/?accountId=silompos">
                    <img width="20%" src="\img\line-icon.png" />
                    <span class="h5 m-0 pl-2">{{
                        $t('contactus')
                        }}</span>
                </CButton>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import "@/util/tabs.css";
import permission from "../../util/permission";
import util from '@/util/util'
import pos from '@/services/local'
import Pagination from '@/containers/Pagination'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-datepicker/index.css'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    components: {
        Pagination,
        VueTimepicker
    },
    data() {
        return {
            giftActive: true,
            importPointActive: false,
            pullPointActive: false,
            isSelfOrderEnabled: false,
            data: [],
            loadingButton: true,
            keyword: '',
            categoryObjectId: '',
            category: [],
            meta_data: {
                last_page: null,
                current_page: 1,
                prev_page_url: null,
                items: 0,
                limit: 25,
                itemCount: null,
            },
            confirmModal: false,
            loading: false,
            message: '',
            footer: false,
            details: [],
            days: {},
            kitchenConfirmModal: false,
            orderShopCollection: null,
            alertStatus: false,
            shopURL: '',
            alertText: '',
            hasPathUniqueName: false,
        };
    },
    computed: {
        ...mapGetters(["shops", "permissions", 'users']),
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId;
            },
            set(newValue) {
                return this.$store.dispatch("setShop", newValue);
            },
        },
        shop() {
            return this.shops.find((i) => i.objectId === this.shopObjectId);
        },
        datashop: {
            get() {
                return this.$store.getters.shopsetting
            },
            set(newValue) {
                return this.$store.dispatch('setShopSetting', newValue)
            },
        },
        plan() {
            const shop = this.shop || {};
            const plan = shop.currentPlan || "standard";
            if (plan === "standard") {
                return 1;
            } else if (plan === "starter" || plan === "starterPlus") {
                return 2;
            } else if (plan === "multiplePOS") {
                return 3;
            } else {
                return 1;
            }
        },
        selfOrderEnabled: {
            get() {
                const shop = this.shop || {};
                const delivery = shop.delivery || {};
                const isSelfOrderEnabled = delivery.isSelfOrderEnabled || false;
                const lock = this.permissions.selfOrder && this.permissions.selfOrder.lock;
                if (isSelfOrderEnabled === true && lock === false) {
                    return true;
                } else {
                    return false;
                }
            },
            set(value) {
                this.isSelfOrderEnabled = value;
            },
        },
        isPermission() {
            return permission.findPermissionRead('selfOrder', this.$route.path)
        },
        isEditData() {
            return permission.findPermissionEdit('selfOrder', this.$route.path)
        },
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
        fields() {
            return [
                { key: 'index', label: '#', _style: 'width:2%', _classes: 'text-dark font-weight-normal' },
                {
                    key: 'remoteImagePath',
                    label: this.$i18n.t('product'),
                    _style: 'width:5%',
                    _classes: 'text-dark font-weight-normal'
                },
                { key: 'SKUName', label: '', _classes: 'font-weight-normal text-dark' },
                {
                    key: 'unit',
                    label: this.$i18n.t('unit'),
                    _style: 'width:10%',
                    _classes: 'text-dark font-weight-normal'
                },
                {
                    key: 'category',
                    label: this.$i18n.t('category'),
                    _style: 'width:15%',
                    _classes: 'text-dark font-weight-normal'
                },
                {
                    key: 'price',
                    label: this.$i18n.t('price'),
                    _classes: 'text-right text-dark font-weight-normal',
                    _style: 'width:10%',
                },
                {
                    key: 'onhandQty',
                    label: this.$i18n.t('onhandQty'),
                    _classes: 'text-right text-dark font-weight-normal ',
                    _style: 'width:10%',
                },
                {
                    key: 'status',
                    label: this.$i18n.t('onSale'),
                    _style: 'width:5%',
                    _classes: 'text-dark font-weight-normal'
                },
                {
                    key: 'recommend',
                    label: this.$i18n.t('recommendMenu'),
                    _style: 'width:5%',
                    _classes: 'text-dark font-weight-normal'
                },
            ]
        },
        items() {
            let data = this.data
            let objects = []
            const currentPage = (this.meta_data.current_page || 1) - 1
            const limit = this.meta_data.limit || 25

            for (let i = 0; i < data.length; i++) {
                const object = data[i]
                const index = (currentPage * limit) + (i + 1)

                // required: product sku stock
                if (object.ProductSKUStock == undefined) {
                    continue
                }

                let remoteImagePath = ''
                let backgroundColor = ''
                if (object.remoteImagePath != undefined) {
                    remoteImagePath = object.remoteImagePath
                    backgroundColor = ''
                } else {
                    remoteImagePath = ''
                    backgroundColor = util.generateColor(object.indexColor)
                }

                let item = {
                    'index': index,
                    'remoteImagePath': remoteImagePath,
                    'backgroundColor': backgroundColor,
                    'SKUName': object.SKUName,
                    'onhandQty': object.ProductSKUStock.onhandQty || 0,
                    'price': '฿ ' + object.SKUPrice,
                    'isMenu': object.isMenu,
                    'isRecommend': object.isRecommend || undefined,
                    'ProductPLU': object.ProductPLU,
                    'objectId': object.objectId,
                    'id': object.id,
                }
                if (object.category !== undefined) {
                    item['category'] = object.category.name || 'N/A'
                } else {
                    item['category'] = 'N/A'
                }
                if (object.unit !== undefined) {
                    item['unit'] = object.unit.name || 'N/A'
                } else {
                    item['unit'] = 'N/A'
                }

                objects.push(item)
            }
            return objects
        },
        URL() {
            return process.env.VUE_APP_QR_ORDER_WEB_URL
        }
    },
    created() {
        this.getCategory()
        this.getProduct()
        this.getOrderShopSetting()
    },
    methods: {
        ...util,
        getOrderShopSetting() {
            const uid = localStorage.getItem('uid')
            const shopObjectId = localStorage.getItem('shopObjectId')
            let params = {
                shopObjectId: shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v2.0/' + uid + '/OrderShopSetting',
                params: params,
                headers: { shopObjectId: shopObjectId }
            }).then((res) => {
                this.orderShopCollection = res?.data?.data[0] || null
                this.shopURL = this.orderShopCollection?.pathUniqueName || ''
                if (this.shopURL !== '') {
                    this.hasPathUniqueName = true
                } else {
                    this.hasPathUniqueName = false
                }
                if (this.validateKitchenTime(this.orderShopCollection)) {
                    this.days = this.orderShopCollection.kitchenTime
                } else {
                    this.days = {
                        Mon: { index: 0, name: "Monday", openTime: "09:00", closeTime: "17:00", closed: false },
                        Tue: { index: 1, name: "Tuesday", openTime: "09:00", closeTime: "17:00", closed: false },
                        Wed: { index: 2, name: "Wednesday", openTime: "09:00", closeTime: "17:00", closed: false },
                        Thu: { index: 3, name: "Thursday", openTime: "09:00", closeTime: "17:00", closed: false },
                        Fri: { index: 4, name: "Friday", openTime: "09:00", closeTime: "17:00", closed: false },
                        Sat: { index: 5, name: "Saturday", openTime: "09:00", closeTime: "17:00", closed: true },
                        Sun: { index: 6, name: "Sunday", openTime: "09:00", closeTime: "17:00", closed: true }
                    }
                }
            })
        },
        showMessageStatus() {
            this.alertStatus = true;
            setTimeout(() => {
                this.alertStatus = false;
            }, 5000);

        },
        validateKitchenTime(orderShopCollection) {
            const expectedKeys = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
            // Check if all expected keys exist in kitchenTime
            for (let key of expectedKeys) {
                if (!orderShopCollection?.kitchenTime?.hasOwnProperty(key)) {
                    return false; // Key missing
                }
                // Validate the structure of each day's object
                const day = orderShopCollection.kitchenTime[key];
                if (typeof day.index !== 'number' ||
                    typeof day.name !== 'string' ||
                    typeof day.openTime !== 'string' ||
                    typeof day.closeTime !== 'string' ||
                    typeof day.closed !== 'boolean') {
                    return false; // Invalid structure
                }
            }
            return true; // Everything is valid
        },
        async saveDataKitchen() {
            this.loading = true
            const shopObjectId = localStorage.getItem('shopObjectId')
            const uid = `${localStorage.getItem('uid')}`

            let data = {
                "shop": {
                    "objectId": shopObjectId,
                    "id": this.datashop.id,
                    "branchName": this.datashop.branchName
                },
                "shopId": this.datashop.id,
                "uid": uid,
                "kitchenTime": this.days,

            }
            if (!this.hasPathUniqueName) {
                data.pathUniqueName = this.shopURL
            }

            if (this.orderShopCollection == null) {
                pos({
                    method: 'post',
                    url: '/api/v2.0/' + this.uid + '/OrderShopSetting',
                    data: data,
                })
                    .then((res) => {
                        this.loading = false
                        this.kitchenConfirmModal = false
                        this.showMessageStatus()
                        this.getOrderShopSetting()
                        window.scroll(0, 0)
                    })
            } else {
                pos({
                    method: 'patch',
                    url: '/api/v2.0/' + uid + '/OrderShopSetting/' + this.orderShopCollection.objectId,
                    data: data,
                })
                    .then((res) => {
                        this.loading = false
                        this.kitchenConfirmModal = false
                        this.getOrderShopSetting()
                        this.showMessageStatus()
                        window.scroll(0, 0)

                    })
            }
        },
        async saveKitchenTimes() {
            // if (this.shopURL == '') {
            //     this.alertText = this.$i18n.t('plsSetDomainName')
            //     this.loading = false
            //     window.scroll(0, 0)
            //     setTimeout(() => {
            //         this.alertText = ''
            //     }, 3000);
            // } else {
            //     if (this.hasPathUniqueName) {
            //         this.kitchenConfirmModal = true
            //     } else {
            //         this.checkPathUniqueName()
            //     }
            // }
            this.kitchenConfirmModal = true
        },
        async checkPathUniqueName() {
            await pos({
                method: 'get',
                url: '/api/v3.0/shop/check/path/uniquename',
                headers: { uniqueName: this.shopURL },
            })
                .then((res) => {
                    if (res.data?.data?.isUsed) {
                        this.alertText = 'domianInUsed'
                        this.loading = false
                        window.scroll(0, 0)
                        setTimeout(() => {
                            this.alertText = ''
                        }, 3000);
                    } else {
                        this.kitchenConfirmModal = true
                    }
                })
        },
        getDays(day) {
            return this.$i18n.t(day.toLowerCase())
        },
        applyToAllDays() {
            const openTime = this.days.Mon.openTime;
            const closeTime = this.days.Mon.closeTime;
            for (let key in this.days) {
                if (!this.days[key].closed) {
                    this.days[key].openTime = openTime;
                    this.days[key].closeTime = closeTime;
                }
            }
        },
        cancel() {
            this.isSelfOrderEnabled = !this.isSelfOrderEnabled
            this.confirmModal = false
        },
        statusPopup() {
            if (this.isSelfOrderEnabled) {
                this.message = this.$i18n.t('confirmSelfOrderDisable')
            } else {
                this.message = this.$i18n.t('confirmSelfOrderEnable')
            }
            this.confirmModal = true
        },
        getCategory() {
            const uid = this.uid
            const shopObjectId = this.shopObjectId
            pos.get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId)
                .then((res) => {
                    this.category = res.data.data
                })
        },
        getProductByCategory(e) {
            this.categoryObjectId = e.target.value
            this.getProduct()
        },
        getProduct(page = 1) {
            this.getCategory()
            this.loadingButton = false
            const uid = this.uid
            const shopObjectId = this.shopObjectId
            let params = {}

            if (this.categoryObjectId === undefined || this.categoryObjectId === '') {
                params = {
                    shopObjectId: shopObjectId,
                    page: page,
                    searchWord: this.keyword,
                    limit: 25,
                }
            } else {
                params = {
                    shopObjectId: shopObjectId,
                    page: page,
                    categoryObjectId: this.categoryObjectId,
                    limit: 25,
                }
            }

            pos.get('/api/v1.0/' + uid + '/Shop/getproduct/data', { params })
                .then((res) => {
                    this.data = res.data.data
                    if (res.data.paginate.items == undefined) {
                        this.meta_data.items = 0
                    } else {
                        this.meta_data.items = res.data.paginate.items
                    }
                    this.meta_data.last_page = res.data.paginate.pageCount
                    this.meta_data.current_page = res.data.paginate.currentPage
                    this.meta_data.itemCount = res.data.paginate.itemCount
                    this.meta_data.limit = res.data.paginate.perPage

                    this.loadingButton = true
                })
        },
        updateData(e, product, option) {
            const plan = this.users.currentPlan
            let data

            if (option == 'isMenu') {
                data = {
                    uid: this.uid,
                    shopObjectId: this.shopObjectId,
                    shopId: this.shop.id,
                    shop: {
                        id: this.shop.id,
                        objectId: this.shop.objectId,
                        branchName: this.shop.branchName,
                    },
                    objectId: product.objectId,
                    id: product.id,
                    isMenu: e,
                }
            } else {
                data = {
                    uid: this.uid,
                    shopObjectId: this.shopObjectId,
                    shopId: this.shop.id,
                    shop: {
                        id: this.shop.id,
                        objectId: this.shop.objectId,
                        branchName: this.shop.branchName,
                    },
                    objectId: product.objectId,
                    id: product.id,
                    isRecommend: e,
                }
            }

            const headers = {
                shopObjectId: this.shopObjectId,
            }

            pos({
                method: 'post',
                url: '/api/v1.0/' + this.uid + '/productsku/edit',
                params: { shopObjectId: this.shopObjectId, plan: plan },
                data: data,
                headers: headers,
            })
                .then((response) => {

                })
                .catch((error) => {
                    console.log(error)
                })
        },
        savedata() {
            const uid = `${localStorage.getItem('shopsUid')}`

            this.loading = true

            let delivery = {
                ...this.shop.delivery,
                ...{ isSelfOrderEnabled: this.isSelfOrderEnabled },
            }
            let data = { ...this.shop, ...{ delivery: delivery } }

            pos({
                method: 'post',
                url: '/api/v1.0/' + uid + '/Shop/updateconfig',
                data: data,
            })
                .then((res) => {
                    setTimeout(() => {
                        this.confirmModal = false
                        this.loading = false
                    }, 1500)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        toggleDetails(index) {
            const position = this.details.indexOf(index)
            position !== -1
                ? this.details.splice(position, 1)
                : this.details.push(index)
        },
        floatValue(value) {
            return parseFloat(value)
        },
        copyURL() {
            let text = this.URL + '/delivery/' + this.shopURL
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text).then(() => {
                }).catch(err => {
                    console.error('Failed to copy text: ', err);
                });
            } else {
                // Fallback for older browsers (execCommand)
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                try {
                    document.execCommand('copy');
                } catch (err) {
                    console.error('Failed to copy text: ', err);
                }
                document.body.removeChild(textArea);
            }
        }
    },
    watch: {
        shopObjectId() {
            this.getProduct()
        },
    },
};
</script>
<style src="../../assets/styles/inventory.css" scoped></style>
<style>
.square-box {
    position: relative;
    width: 20%;
    overflow: hidden;
    border-radius: 3px;
}

.square-box:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.card[data-v-08db0b29] {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.line-btn {
    width: 180px;
    background-color: #00ba01;
    display: flex;
    align-items: center;
    color: white;
    border-radius: 7px;
}

.vue__time-picker {
    width: 10em;
}

.vue__time-picker input.display-time {
    width: 10em;
}

@media only screen and (max-width: 600px) {

    .vue__time-picker,
    .vue__time-picker input.display-time {
        width: 5em;
    }
}

.tabs .slider {
    position: relative;
    width: 50%;
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}

.time-picker-header {
    height: 35px;
    overflow: hidden;
    border: 1px solid #ebedef;
    background-color: #f6f6f6;
}
</style>
